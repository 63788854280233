import React from 'react'

function About() {
  return (
    <section className="resume-section" id="about">
      <div className="resume-section-content">
        <h1 className="mb-0">
          Soheyb Merah
        </h1>
        <p className="lead mt-5">
        AM a full-stack developer and machine learning engineer with experience in building scalable web, mobile, and AI-powered applications. 
        <br />
        My expertise includes backend, frontend, mobile development, databases, and DevOps, I've worked on low-code solutions, and data-driven systems. 
        <br />
        I love focusing on emerging web technologies and reinforcement learning while freelancing and developing innovative solutions.
         <br />
        </p>
        <div className="social-icons d-flex justify-content-center mt-5">
          <a className="social-icon" href="https://www.linkedin.com/in/soheyb-merah-1b297a13b/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
          <a className="social-icon" href="https://github.com/splimter/" target="_blank" rel="noopener noreferrer"><i className="fab fa-github" /></a>
          <a className="social-icon" href="https://www.facebook.com/splimterxtenno/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        </div>
        <div className="subheading text-center mt-3">
          <a href="mailto:merah.soheyb@gmail.com">merah.soheyb@gmail.com</a>
        </div>
      </div>
    </section>

  )
}

export default About
